@import url(https://use.typekit.net/kid6drd.css);
@import url(https://fonts.googleapis.com/css2?family=Special+Elite&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Ubuntu&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato&display=swap);
@import url(https://fonts.googleapis.com/css2?family=IBM+Plex+Sans&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto+Mono:ital@1&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Kavivanar&display=swap);
@font-face {
    font-family: 'Wilson';
    src: url(/static/media/Wilson.0738817c.otf);
    font-style: normal;
}

@font-face {
    font-family: 'Gotham';
    src: url(/static/media/Gotham-Book.9019ab71.otf);
    font-style: normal;
}


@font-face {
    font-family: 'Reckless';
    src: url(/static/media/RecklessNeue-Medium.0026e3bc.otf);
    font-style: normal;
}

@font-face {
    font-family: 'Avara';
    src: url(/static/media/avara.4b7d36ca.woff);
    font-style: normal;
}

@font-face {
    font-family: 'Terminal';
    src: url(/static/media/terminal-grotesque-webfont.fdf812bf.woff);
    font-style: normal;
}




@font-face {
    font-family: 'Hershey Times';
    src: url(/static/media/Hershey-Noailles-Times-Duplex-Italic-Regular.da156881.woff);
    font-style: normal;
}


@font-face {
    font-family: 'Hershey Futura';
    src: url(/static/media/Hershey-Noailles-Futura-Duplex-Regular.1c1af4b8.woff);
    font-style: normal;
}

@font-face {
    font-family: 'Bodoni';
    src: url(/static/media/BodoniBook.4c1d31b2.otf);
    font-style: normal;
}

@font-face {
    font-family: 'OCR';
    src: url(/static/media/OCR-A-Regular.dd17efd1.otf);
    font-style: normal;
}

@font-face {
    font-family: 'Redaction';
    src: url(/static/media/Redaction_70-Regular.4854491b.woff2);
    font-style: normal;
}

@font-face {
    font-family: 'Alte Haas';
    src: url(/static/media/AlteHaasGroteskRegular.38025932.ttf);
    font-style: normal;
}


@font-face {
    font-family: 'Berthold';
    src: url(/static/media/Berthold-Akzidenz-Grotesk-BE-Bold.f528a3cd.otf);
    font-style: normal;
}

@font-face {
    font-family: 'Berthold Medium';
    src: url(/static/media/Berthold-Akzidenz-Grotesk-BE-Medium.0c011f02.otf);
    font-style: normal;
}

@font-face {
    font-family: 'Windsor Cond Bold';
    src: url(/static/media/WindsorBQ-Elongated.c38eac33.otf);
    font-style: normal;
}

@font-face {
    font-family: 'Windsor Cond Light';
    src: url(/static/media/WindsorEF-LightCondensed.3e4cee64.otf);
    font-style: normal;
}

@font-face {
    font-family: 'Fira Code';
    src: url(/static/media/FiraCode-Regular.d60b1090.ttf);
    font-style: normal;
}

@font-face {
    font-family: 'Gill Sans Italic';
    src: url(/static/media/GillSansStd-Italic.cd2d9f45.otf);
    font-style: normal;
}


@font-face {
    font-family: 'Franklin Gothic';
    src: url(/static/media/franklinGothic.5f5ec1fd.otf);
    font-style: normal;
}

@font-face {
    font-family: 'Olive';
    src: url(/static/media/Olive_DEMO.527c6198.otf);
    font-style: normal;
}

@font-face {
    font-family: 'Parabole Trial';
    src: url(/static/media/ParaboleTrial-Regular.80a3700d.otf);
    font-style: normal;
}


@font-face {
    font-family: 'Daniel';
    src: url(/static/media/danielbd.96157c3e.ttf);
    font-style: normal;
}






/* american */
.author134492.authorstyle, .author134492 {
  font-family: 'Parabole Trial';
  color: #7500DB;
}

/* zai */
.author88210.authorstyle, .author88210 {
  font-family: 'Kavivanar', cursive;
  /* font-family: 'Hershey', cursive;
  letter-spacing: -1px; */
  /* color: #ff7a3f; */
  color: #ff8d1d;
}




/* tristian */
.author173504.authorstyle {
  font-family: bebas-neue-pro, sans-serif;
  font-weight: 400;
  font-style: normal;
  /* color: #ffc20d; */
  color: #ffcf0d;
}

/* krystal */
.author173503.authorstyle {
  font-family: "Courier";
  /* color: teal; */
  color: #20B2AA;
}

/* teresa */
.author173506.authorstyle {
  font-family: Arial;
  /* color: #5113bd; */
  color: #7500DB;
}

/* angus */
.author173746.authorstyle {
  font-family: "Special Elite", cursive;
  font-style: normal;
  color: #000;
}
.author173746.authorstyle p p,
span.author173746.authorstyle ,
.author173746.authorstyle li,
.author173746.authorstyle span.contrib
{
  background-color: #c9f2f2;
}


/* david */
.author37613.authorstyle {
  font-family: 'Wilson';
  font-style: normal;
  color: #000;
}
.author37613.authorstyle p p,
span.author37613.authorstyle ,
.author37613.authorstyle li,
.author37613.authorstyle span.contrib
{
  background-color: #E6E6FA;
}



/* lauren g */
.author17810.authorstyle {
  font-family: "Special Elite", cursive;
  font-style: normal;
  color: #000;
}
.author17810.authorstyle p p,
span.author17810.authorstyle ,
.author17810.authorstyle li,
.author17810.authorstyle span.contrib
{
  /* background-color: #f2ffad; */
  background-color: yellow;
}




/* tash */
.author159845.authorstyle {
  font-family: "Ubuntu", sans-serif;
  color: #df73ff;
  font-weight: 300;
}


/* diana */
.author173736.authorstyle {
  font-family: "Ubuntu", sans-serif;
  color: #7851A9;
  font-weight: 300;
}


/* jessica */
.author173499.authorstyle {
  font-family: Helvetica;
  color: #289d2c;
}

/* paras */
.author173502.authorstyle {
  font-family: Courier;
  color: #6b8e23;
}


/* ryan */
.author23411.authorstyle {
  font-family: 'Lato', sans-serif;
  color: #20B2AA;
  font-style: normal;
  font-weight: 400;
}

/* kevin */
.author173500.authorstyle {
  font-family: 'Reckless';
  /* color: #DD1327; */
  color: #FF0000;
  line-height: 1.3;
}

/* trevor */
.author173534.authorstyle {
  font-family: Arial;
  color: purple;
}

/* timur */
.author7454.authorstyle {
  font-family: Times;
  color: #ff0000;
} .author7454.authorstyle p {
  font-style: italic;
  letter-spacing: -.03em;
}

/* sara */
.author173494.authorstyle {
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 500;
  color: #0000ee;
}

/* connor */
.author13842.authorstyle {
  font-family: 'IBM Plex Sans', sans-serif;
  color: #B9E7B6;
 -webkit-text-stroke-width: 1px;
 -webkit-text-stroke-color: #8CBB89;
  font-weight: 700;
} .author13842.authorstyle p p,
.author13842.authorstyle li {
  /* background-color: #B9E7B6; */
}


/* lissa */
.author173501.authorstyle {
  font-family: 'Bodoni';
  color: #800020;
}

/* miranda */
.author173613.authorstyle {
  font-family: 'OCR';
  /* color: #FF7A66; */
  color: #ff8d1d;
}

/* cameron */
.author173508.authorstyle {
  font-family: 'Alte Haas';
  color: yellow;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #000;
}

/* lauren m. */
.author173730.authorstyle {
  font-family: 'Arial';
  color: #FF00FF;
}

/* Heather */
.author173830.authorstyle {
  font-family: 'Redaction';
  color: #ff008a;
}


/* rahel */
.author162734.authorstyle {
  font-family: Georgia;
  /* color: #000066; */
  color: #0000ee;
}


/* sus */
.author173737.authorstyle {
  font-family: 'Gill Sans Italic';
  /* color: #0511CF; */
  color: #0000ee;
}

/* tyler */
.author25047.authorstyle {
  font-family: 'Fira Code';
  color: #FF0000;
}

/* makshya */
.author173495.authorstyle {
  font-family: Helvetica;
  color: #F5C4C0;
  /* font-weight: bold; */
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #e09d98;
}


/* .author173495.authorstyle {
  font-style: normal;
  font-family: Helvetica;
  color: #000;
}
.author173495.authorstyle p p,
.author173495.authorstyle li {
  background-color: #F5C4C0;
} */





/* matt ross */
.author126808.authorstyle {
  font-family: 'Roboto Mono', monospace;
  /* color: #556b2f; */
  color: #6b8e23;
} .author126808.authorstyle p {
  font-style: italic;
}

/* ilona */
.author72134.authorstyle {
  font-family: 'Kavivanar', cursive;
  color: #CC7722;
}



/* lluvia */
.author160124.authorstyle {
  font-family: 'Daniel';
  color: #E4007C;
}


/* leanne */
.author173505.authorstyle {
  /* font-family: 'Hershey Futura'; */
  font-family: 'Gotham';
  color: #159ED9;
}



/* ————————————————————————————————————————————————————————————————————————————————————— */


/* makayla */
.author102862.authorstyle {
  font-family: 'Avara';
  /* color: orange; */
  color: #ffcf0d;
}

/* bronte */
.author173511.authorstyle {
  font-family: 'Hershey Times', cursive;
  /* color: #b08213; */
  color: #CC7722;
} .author173511.authorstyle p {
  letter-spacing: -1px;
}



/* —————————————— */

a {
  color: inherit;
  /* text-decoration-style: dotted; */
}


/* p {
    margin-block-start: 0em !important;
    margin-block-end: 0in !important;
} */

p {
  mix-blend-mode: multiply;
}
.description {
  border-left: .03in solid #000;
  /* margin-top: 0.25in; */
  /* font-size: calc(1em * 0.75); */
}

img {
  margin-bottom: 0.25in !important;
}
p.description{
  font-size: 12pt;
  padding-left: .2in;
  padding-top: .05in;
  padding-bottom: .05in !important;
  line-height: 1.4;
  -webkit-margin-before: 0in !important;
          margin-block-start: 0in !important;
  -webkit-margin-after: 0.25in !important;
          margin-block-end: 0.25in !important;
  display: block;
  margin-bottom: 0in;
}

.author173504 p.description {
  font-size: 14pt !important;
}

h2,  p.description h2{
  font-size: 12pt;
  padding-left: 0in;
  padding-top: 0in;
  padding-bottom: 0in !important;
  -webkit-margin-before: 0in !important;
          margin-block-start: 0in !important;
  -webkit-margin-after: 0in !important;
          margin-block-end: 0in !important;
  display: block;
  margin-bottom: 0in;
  font-weight: normal;
}

p ol {
  -webkit-margin-after: 0.25in !important;
          margin-block-end: 0.25in !important;
}



.description ul {
  -webkit-margin-before: 0 !important;
          margin-block-start: 0 !important;
  -webkit-margin-after: 0 !important;
          margin-block-end: 0 !important;
  -webkit-padding-start: 0px;
          padding-inline-start: 0px;
}


.description ul li {
  list-style: none;
}



.neutral  {
  font-family: "Special Elite", cursive;
  /* letter-spacing: .5px; */
  /* font-size: 16pt; */

  /* font-family: 'Berthold Medium'; */
  /* font-size: 12pt; */
  font-size: 10pt;
  /* font-weight: bold; */

  color: #000 !important;
  font-style: none !important;
  /* font-weight: bold; */
  /* padding-top: 0.25in; */
  line-height: 1.25;
  margin: 0.5em 0;
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-all;
  word-break: break-all;
  word-break: break-word;
  -webkit-text-fill-color:  none !important;
 -webkit-text-stroke-width: 0px !important;
 -webkit-text-stroke-color: none !important;
}

.page-header  {
  /* font-family: 'Berthold Medium'; */
  /* font-size: 14pt !important; */
  /* font-size: 12pt !important; */

  /* letter-spacing: .5px; */
  font-family: "Special Elite", cursive;
  /* font-size: 20pt !important; */

  color: #000;
  font-style: none !important;

  /* font-weight: bold; */
  font-weight: normal;
  -webkit-text-fill-color:  none !important;
 -webkit-text-stroke-width: 0px !important;
 -webkit-text-stroke-color: none !important;
}

.page-header {
    text-transform: lowercase;
    text-transform: capitalize;
}

.page-header::first-letter {
    text-transform: uppercase;
}

img:hover {
  -webkit-filter: none;
          filter: none;
}

ol {
    margin-top: 0;
    margin-bottom: 0;
    list-style-type: upper-roman;
    padding-left: 0.15in !important;
}

.loader {
  margin: 60px auto;
  border: 10px solid #555; /* Light grey */
  border-top: 10px solid #fff; /* Blue */
  border-radius: 50%;
  width: 60px;
  height: 60px;
  -webkit-animation: spin 2s linear infinite;
          animation: spin 2s linear infinite;
}

@-webkit-keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}


blockquote {
  -webkit-margin-before: 0em  !important;
          margin-block-start: 0em  !important;
    -webkit-margin-after: 0em !important;
            margin-block-end: 0em !important;
    -webkit-margin-start: 0px !important;
            margin-inline-start: 0px !important;
    -webkit-margin-end: 0px !important;
            margin-inline-end: 0px !important;
    font-style: italic;
}


.📖-controls {
  opacity: 0;

}


.📖-left .block  {
    left: -.45in;
}

.📖-right .block  {
    left: -.75in;
}

.page.📖-continuation.📖-continues {
    /* margin-top: 0.335in; */
    margin-top: 0.25in !important;
}


.margin-top {
  margin-top: 1in;
  display: block;
}

.About {
  font-size: 15pt;
  line-height: 1.3;
  margin-bottom: .3in;
  /* line-height: 1.1; */
}

.Ack{
  line-height: 1.3 !important;
  -webkit-margin-before: 0em !important;
          margin-block-start: 0em !important;
  /* margin-block-end: 0in !important; */
}


.Asterisk {
  text-indent: -.12in;
  /* margin-left: .05in; */
  line-height: 1;
  font-size: 15pt;
}


.About u {
  /* text-decoration-style: dotted; */

}

sup.wordinline {
  font-family: "Special Elite", cursive;
  font-size: 10pt;
  /* font-weight: bold; */
}


.center {
  /* text-align: center; */
  text-align: left;
  /* margin-top: 1in;
  margin-bottom: -1in; */
}

.flex {
  display: flex;
  justify-content: space-between;
}



.contributors {
  font-size: 16pt;
  line-height: 1.75;
  display: inline-block;
  margin-right: 25px;
  text-align: center;
}

.contributors sup {
  color: #000 !important;
}


/* hide alphabet page metadata */
.page8263332 .neutral, .page8263332 .page-header,
.page8263374 .neutral, .page8263374 .page-header,
.page8263372 .neutral, .page8263372 .page-header,
.page8263365 .neutral, .page8263365 .page-header,
.page8263367 .neutral, .page8263367 .page-header,
.page8263366 .neutral, .page8263366 .page-header,
.page8263371 .neutral, .page8263371 .page-header,
.page8263370 .neutral, .page8263370 .page-header,
.page8263369 .neutral, .page8263369 .page-header,
.page8263368 .neutral, .page8263368 .page-header,
.page8263453 .neutral, .page8263453 .page-header,
.page8263440 .neutral, .page8263440 .page-header,
.page8263439 .neutral, .page8263439 .page-header,
.page8263452 .neutral, .page8263452 .page-header,
.page8263436 .neutral, .page8263436 .page-header,
.page8263437 .neutral, .page8263437 .page-header,
.page8263443 .neutral, .page8263443 .page-header,
.page8263441 .neutral, .page8263441 .page-header,
.page8263449 .neutral, .page8263449 .page-header,
.page8263446 .neutral, .page8263446 .page-header,
.page8263438 .neutral, .page8263438 .page-header,
.page8263442 .neutral, .page8263442 .page-header,
.page8263445 .neutral, .page8263445 .page-header,
.page8263444 .neutral, .page8263444 .page-header,
.page8263450 .neutral, .page8263450 .page-header,
.page8263447 .neutral, .page8263447 .page-header

 {
  display: none;
}




.page8263332 img,
.page8263374 img,
.page8263372 img,
.page8263365 img,
.page8263367 img,
.page8263366 img,
.page8263371 img,
.page8263370 img,
.page8263369 img,
.page8263368 img,
.page8263453 img,
.page8263440 img,
.page8263439 img,
.page8263452 img,
.page8263436 img,
.page8263437 img,
.page8263443 img,
.page8263441 img,
.page8263449 img,
.page8263446 img,
.page8263438 img,
.page8263442 img,
.page8263445 img,
.page8263444 img,
.page8263450 img,
.page8263447 img

 {
  padding-top: 1.5in;
}


.block {
  mix-blend-mode: multiply;
  width: 5.5in;
  height: 8.5in !important;
  display: inline-block;
  z-index: -1;
  position: absolute;
  /* left: -.35in; */
  top: -.4in;
  /* background-size: contain; */
  background-size: cover;
  background-repeat: no-repeat;
}

.📖-flow-box {
  mix-blend-mode: multiply;
}

.covertitle {
  background-image: url(/static/media/cover.80df5ee2.jpg);
  border: 1px solid #555;
  box-sizing: border-box;
}

.sc-fzpkJw.iGMbuA{
  background-image: url(/static/media/tyleryin-environmental_injustice.777111d6.png);
  mix-blend-mode: multiply;
}

.block7531586 {
  background-image: url(/static/media/tyleryin-environmental_injustice.777111d6.png);
}

.block8008214 {
  background-image: url(/static/media/tyleryin-environmental_injustice-2.230a063c.png);
}

/* .block7930917{
  background-image: url("../../overlays/sus-blackgooey.png");
} */

.block7531583{
  background-image: url(/static/media/sus-accountability.2ba01712.png);
}

.block7445152{
  background-image: url(/static/media/sus-trust.9b6867e8.png);
}

.block7445521{
  background-image: url(/static/media/sus-time.405fb044.png);
}

.block7998591{
  background-image: url(/static/media/tristan-banopticon.1467a21e.png);
}


.block7455056{
  background-image: url(/static/media/tash-hardware.a105427c.png);
}


.block7925813{
  background-image: url(/static/media/sus-resistance.1d2f3a45.png);
}

.block7774079{
  background-image: url(/static/media/sus-surveillance.e8ed430d.png);
}

.block7843218{
  background-image: url(/static/media/sus-debt.3e3586eb.png);
}

.block7838438{
  background-image: url(/static/media/sus-debt2.214e0ba8.png);
}

.block7828098{
  background-image: url(/static/media/sus-algorithm.86957ebc.png);
}

.block7457765{
  background-image: url(/static/media/sus-equity.f8239b45.png);
}

.block7766334{
  background-image: url(/static/media/sus-future.799bd158.png);
}

.block7994062{
  background-image: url(/static/media/tyler-carceral.62ac20b1.png);
}

.block7765904{
  background-image: url(/static/media/tyler-commodity.b2dcc61b.png);
}

.block7765004{
  background-image: url(/static/media/tyler-credible.96257d32.png);
}

.block7788288{
  background-image: url(/static/media/sus-biometrics.a542d052.png);
}

.block7925809{
  background-image: url(/static/media/tyler-brand.fdaffd77.png);
}

.block7843274{
  background-image: url(/static/media/tyler-lumpen.af1d4083.png);
}

.block7531644{
  background-image: url(/static/media/tyler-freelabor.2f04eb4b.png);
}

.block8077226{
  background-image: url(/static/media/tyler-debt-predict.56fe0887.png);
}

.block8070415{
  background-image: url(/static/media/tyler-debt-predict2.be95c69a.png);
}

.block7765901{
  background-image: url(/static/media/tyler-biometrics.2ae836e8.png);
}

.block7692504{
  background-image: url(/static/media/tyler-manifest.a9f17fb4.png);
}

.block8008222{
  background-image: url(/static/media/tyler-california.a078cfa8.png);
}

.block7994028{
  background-image: url(/static/media/tyler-looting.a3e013e2.png);
}

.block7444335{
  background-image: url(/static/media/tyler-interface.43cac91c.png);
}

.block7429999{
  background-image: url(/static/media/tyler-interface-surveil.3d47f793.png);
}

.block7822883{
  background-image: url(/static/media/tyler-biometrics2.8b18e73b.png);
}

.block7842743{
  background-image: url(/static/media/tyler-gratuitous.34d4abf4.png);
}

.block7416844{
  background-image: url(/static/media/zai-abstraction.1cf1460c.png);
}

.block7445808{
  background-image: url(/static/media/zai-abstraction2.b47dfa3c.png);
}

.block7446147{
  background-image: url(/static/media/zai-causal.97058c37.png);
}

.block7428890{
  background-image: url(/static/media/zai-knowledge.77c034a4.png);
}

.block7774200{
  background-image: url(/static/media/zai-empire.c0d91dff.png);
}

.block7523613{
  background-image: url(/static/media/zai-biometric.7b6efba3.png);
}

.block7925812{
  background-image: url(/static/media/zai-ontology.00abe1a6.png);
}

.block7765822{
  background-image: url(/static/media/zai-stolen.e29ea450.png);
}

.block7768290{
  background-image: url(/static/media/zai-anthro.2ae1cca2.png);
}

.block7768407{
  background-image: url(/static/media/zai-anthro2.6a087c6f.png);
}

.block7929183{
  background-image: url(/static/media/zai-skin.9c778bdb.png);
}

.block7838628{
  background-image: url(/static/media/zai-body.c2251ae5.png);
}

.block7925766{
  background-image: url(/static/media/zai-pig.21ff3258.png);
}

.block7446448{
  background-image: url(/static/media/zai-input.996f7baa.png);
}

.block7443310{
  background-image: url(/static/media/zai-analog.511c5061.png);
}

.page8223500 .description,.sc-fznyAO.juolFQ hr,
body > div.📖-root.📖-show-crop.📖-view-preview > div.📖-zoom-scaler > div > div:nth-child(275) > div.📖-page.📖-left > div.📖-page-background {
  display: none !important;
}

