

/* —————————————— */

a {
  color: inherit;
  /* text-decoration-style: dotted; */
}


/* p {
    margin-block-start: 0em !important;
    margin-block-end: 0in !important;
} */

p {
  mix-blend-mode: multiply;
}
.description {
  border-left: .03in solid #000;
  /* margin-top: 0.25in; */
  /* font-size: calc(1em * 0.75); */
}

img {
  margin-bottom: 0.25in !important;
}
p.description{
  font-size: 12pt;
  padding-left: .2in;
  padding-top: .05in;
  padding-bottom: .05in !important;
  line-height: 1.4;
  margin-block-start: 0in !important;
  margin-block-end: 0.25in !important;
  display: block;
  margin-bottom: 0in;
}

.author173504 p.description {
  font-size: 14pt !important;
}

h2,  p.description h2{
  font-size: 12pt;
  padding-left: 0in;
  padding-top: 0in;
  padding-bottom: 0in !important;
  margin-block-start: 0in !important;
  margin-block-end: 0in !important;
  display: block;
  margin-bottom: 0in;
  font-weight: normal;
}

p ol {
  margin-block-end: 0.25in !important;
}



.description ul {
  margin-block-start: 0 !important;
  margin-block-end: 0 !important;
  padding-inline-start: 0px;
}


.description ul li {
  list-style: none;
}



.neutral  {
  font-family: "Special Elite", cursive;
  /* letter-spacing: .5px; */
  /* font-size: 16pt; */

  /* font-family: 'Berthold Medium'; */
  /* font-size: 12pt; */
  font-size: 10pt;
  /* font-weight: bold; */

  color: #000 !important;
  font-style: none !important;
  /* font-weight: bold; */
  /* padding-top: 0.25in; */
  line-height: 1.25;
  margin: 0.5em 0;
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-all;
  word-break: break-all;
  word-break: break-word;
  -webkit-text-fill-color:  none !important;
 -webkit-text-stroke-width: 0px !important;
 -webkit-text-stroke-color: none !important;
}

.page-header  {
  /* font-family: 'Berthold Medium'; */
  /* font-size: 14pt !important; */
  /* font-size: 12pt !important; */

  /* letter-spacing: .5px; */
  font-family: "Special Elite", cursive;
  /* font-size: 20pt !important; */

  color: #000;
  font-style: none !important;

  /* font-weight: bold; */
  font-weight: normal;
  -webkit-text-fill-color:  none !important;
 -webkit-text-stroke-width: 0px !important;
 -webkit-text-stroke-color: none !important;
}

.page-header {
    text-transform: lowercase;
    text-transform: capitalize;
}

.page-header::first-letter {
    text-transform: uppercase;
}

img:hover {
  filter: none;
}

ol {
    margin-top: 0;
    margin-bottom: 0;
    list-style-type: upper-roman;
    padding-left: 0.15in !important;
}

.loader {
  margin: 60px auto;
  border: 10px solid #555; /* Light grey */
  border-top: 10px solid #fff; /* Blue */
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}


blockquote {
  margin-block-start: 0em  !important;
    margin-block-end: 0em !important;
    margin-inline-start: 0px !important;
    margin-inline-end: 0px !important;
    font-style: italic;
}


.📖-controls {
  opacity: 0;

}


.📖-left .block  {
    left: -.45in;
}

.📖-right .block  {
    left: -.75in;
}

.page.📖-continuation.📖-continues {
    /* margin-top: 0.335in; */
    margin-top: 0.25in !important;
}


.margin-top {
  margin-top: 1in;
  display: block;
}

.About {
  font-size: 15pt;
  line-height: 1.3;
  margin-bottom: .3in;
  /* line-height: 1.1; */
}

.Ack{
  line-height: 1.3 !important;
  margin-block-start: 0em !important;
  /* margin-block-end: 0in !important; */
}


.Asterisk {
  text-indent: -.12in;
  /* margin-left: .05in; */
  line-height: 1;
  font-size: 15pt;
}


.About u {
  /* text-decoration-style: dotted; */

}

sup.wordinline {
  font-family: "Special Elite", cursive;
  font-size: 10pt;
  /* font-weight: bold; */
}


.center {
  /* text-align: center; */
  text-align: left;
  /* margin-top: 1in;
  margin-bottom: -1in; */
}

.flex {
  display: flex;
  justify-content: space-between;
}



.contributors {
  font-size: 16pt;
  line-height: 1.75;
  display: inline-block;
  margin-right: 25px;
  text-align: center;
}

.contributors sup {
  color: #000 !important;
}


/* hide alphabet page metadata */
.page8263332 .neutral, .page8263332 .page-header,
.page8263374 .neutral, .page8263374 .page-header,
.page8263372 .neutral, .page8263372 .page-header,
.page8263365 .neutral, .page8263365 .page-header,
.page8263367 .neutral, .page8263367 .page-header,
.page8263366 .neutral, .page8263366 .page-header,
.page8263371 .neutral, .page8263371 .page-header,
.page8263370 .neutral, .page8263370 .page-header,
.page8263369 .neutral, .page8263369 .page-header,
.page8263368 .neutral, .page8263368 .page-header,
.page8263453 .neutral, .page8263453 .page-header,
.page8263440 .neutral, .page8263440 .page-header,
.page8263439 .neutral, .page8263439 .page-header,
.page8263452 .neutral, .page8263452 .page-header,
.page8263436 .neutral, .page8263436 .page-header,
.page8263437 .neutral, .page8263437 .page-header,
.page8263443 .neutral, .page8263443 .page-header,
.page8263441 .neutral, .page8263441 .page-header,
.page8263449 .neutral, .page8263449 .page-header,
.page8263446 .neutral, .page8263446 .page-header,
.page8263438 .neutral, .page8263438 .page-header,
.page8263442 .neutral, .page8263442 .page-header,
.page8263445 .neutral, .page8263445 .page-header,
.page8263444 .neutral, .page8263444 .page-header,
.page8263450 .neutral, .page8263450 .page-header,
.page8263447 .neutral, .page8263447 .page-header

 {
  display: none;
}




.page8263332 img,
.page8263374 img,
.page8263372 img,
.page8263365 img,
.page8263367 img,
.page8263366 img,
.page8263371 img,
.page8263370 img,
.page8263369 img,
.page8263368 img,
.page8263453 img,
.page8263440 img,
.page8263439 img,
.page8263452 img,
.page8263436 img,
.page8263437 img,
.page8263443 img,
.page8263441 img,
.page8263449 img,
.page8263446 img,
.page8263438 img,
.page8263442 img,
.page8263445 img,
.page8263444 img,
.page8263450 img,
.page8263447 img

 {
  padding-top: 1.5in;
}


.block {
  mix-blend-mode: multiply;
  width: 5.5in;
  height: 8.5in !important;
  display: inline-block;
  z-index: -1;
  position: absolute;
  /* left: -.35in; */
  top: -.4in;
  /* background-size: contain; */
  background-size: cover;
  background-repeat: no-repeat;
}

.📖-flow-box {
  mix-blend-mode: multiply;
}

.covertitle {
  background-image: url("../../overlays/cover.jpg");
  border: 1px solid #555;
  box-sizing: border-box;
}

.sc-fzpkJw.iGMbuA{
  background-image: url("../../overlays/tyleryin-environmental_injustice.png");
  mix-blend-mode: multiply;
}

.block7531586 {
  background-image: url("../../overlays/tyleryin-environmental_injustice.png");
}

.block8008214 {
  background-image: url("../../overlays/tyleryin-environmental_injustice-2.png");
}

/* .block7930917{
  background-image: url("../../overlays/sus-blackgooey.png");
} */

.block7531583{
  background-image: url("../../overlays/sus-accountability.png");
}

.block7445152{
  background-image: url("../../overlays/sus-trust.png");
}

.block7445521{
  background-image: url("../../overlays/sus-time.png");
}

.block7998591{
  background-image: url("../../overlays/tristan-banopticon.png");
}


.block7455056{
  background-image: url("../../overlays/tash-hardware.png");
}


.block7925813{
  background-image: url("../../overlays/sus-resistance.png");
}

.block7774079{
  background-image: url("../../overlays/sus-surveillance.png");
}

.block7843218{
  background-image: url("../../overlays/sus-debt.png");
}

.block7838438{
  background-image: url("../../overlays/sus-debt2.png");
}

.block7828098{
  background-image: url("../../overlays/sus-algorithm.png");
}

.block7457765{
  background-image: url("../../overlays/sus-equity.png");
}

.block7766334{
  background-image: url("../../overlays/sus-future.png");
}

.block7994062{
  background-image: url("../../overlays/tyler-carceral.png");
}

.block7765904{
  background-image: url("../../overlays/tyler-commodity.png");
}

.block7765004{
  background-image: url("../../overlays/tyler-credible.png");
}

.block7788288{
  background-image: url("../../overlays/sus-biometrics.png");
}

.block7925809{
  background-image: url("../../overlays/tyler-brand.png");
}

.block7843274{
  background-image: url("../../overlays/tyler-lumpen.png");
}

.block7531644{
  background-image: url("../../overlays/tyler-freelabor.png");
}

.block8077226{
  background-image: url("../../overlays/tyler-debt-predict.png");
}

.block8070415{
  background-image: url("../../overlays/tyler-debt-predict2.png");
}

.block7765901{
  background-image: url("../../overlays/tyler-biometrics.png");
}

.block7692504{
  background-image: url("../../overlays/tyler-manifest.png");
}

.block8008222{
  background-image: url("../../overlays/tyler-california.png");
}

.block7994028{
  background-image: url("../../overlays/tyler-looting.png");
}

.block7444335{
  background-image: url("../../overlays/tyler-interface.png");
}

.block7429999{
  background-image: url("../../overlays/tyler-interface-surveil.png");
}

.block7822883{
  background-image: url("../../overlays/tyler-biometrics2.png");
}

.block7842743{
  background-image: url("../../overlays/tyler-gratuitous.png");
}

.block7416844{
  background-image: url("../../overlays/zai-abstraction.png");
}

.block7445808{
  background-image: url("../../overlays/zai-abstraction2.png");
}

.block7446147{
  background-image: url("../../overlays/zai-causal.png");
}

.block7428890{
  background-image: url("../../overlays/zai-knowledge.png");
}

.block7774200{
  background-image: url("../../overlays/zai-empire.png");
}

.block7523613{
  background-image: url("../../overlays/zai-biometric.png");
}

.block7925812{
  background-image: url("../../overlays/zai-ontology.png");
}

.block7765822{
  background-image: url("../../overlays/zai-stolen.png");
}

.block7768290{
  background-image: url("../../overlays/zai-anthro.png");
}

.block7768407{
  background-image: url("../../overlays/zai-anthro2.png");
}

.block7929183{
  background-image: url("../../overlays/zai-skin.png");
}

.block7838628{
  background-image: url("../../overlays/zai-body.png");
}

.block7925766{
  background-image: url("../../overlays/zai-pig.png");
}

.block7446448{
  background-image: url("../../overlays/zai-input.png");
}

.block7443310{
  background-image: url("../../overlays/zai-analog.png");
}

.page8223500 .description,.sc-fznyAO.juolFQ hr,
body > div.📖-root.📖-show-crop.📖-view-preview > div.📖-zoom-scaler > div > div:nth-child(275) > div.📖-page.📖-left > div.📖-page-background {
  display: none !important;
}
