@import url("https://use.typekit.net/kid6drd.css");
@import url("https://fonts.googleapis.com/css2?family=Special+Elite&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Ubuntu&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato&display=swap");
@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto+Mono:ital@1&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Kavivanar&display=swap");





@font-face {
    font-family: 'Wilson';
    src: url('../../fonts/Wilson.otf');
    font-style: normal;
}

@font-face {
    font-family: 'Gotham';
    src: url('../../fonts/Gotham-Book.otf');
    font-style: normal;
}


@font-face {
    font-family: 'Reckless';
    src: url('../../fonts/RecklessNeue-Medium.otf');
    font-style: normal;
}

@font-face {
    font-family: 'Avara';
    src: url('../../fonts/avara.woff');
    font-style: normal;
}

@font-face {
    font-family: 'Terminal';
    src: url('../../fonts/terminal-grotesque-webfont.woff');
    font-style: normal;
}




@font-face {
    font-family: 'Hershey Times';
    src: url('../../fonts/Hershey-Noailles-Times-Duplex-Italic-Regular.woff');
    font-style: normal;
}


@font-face {
    font-family: 'Hershey Futura';
    src: url('../../fonts/Hershey-Noailles-Futura-Duplex-Regular.woff');
    font-style: normal;
}

@font-face {
    font-family: 'Bodoni';
    src: url('../../fonts/BodoniBook.otf');
    font-style: normal;
}

@font-face {
    font-family: 'OCR';
    src: url('../../fonts/OCR-A-Regular.otf');
    font-style: normal;
}

@font-face {
    font-family: 'Redaction';
    src: url('../../fonts/Redaction_70-Regular.woff2');
    font-style: normal;
}

@font-face {
    font-family: 'Alte Haas';
    src: url('../../fonts/AlteHaasGroteskRegular.ttf');
    font-style: normal;
}


@font-face {
    font-family: 'Berthold';
    src: url('../../fonts/Berthold-Akzidenz-Grotesk-BE-Bold.otf');
    font-style: normal;
}

@font-face {
    font-family: 'Berthold Medium';
    src: url('../../fonts/Berthold-Akzidenz-Grotesk-BE-Medium.otf');
    font-style: normal;
}

@font-face {
    font-family: 'Windsor Cond Bold';
    src: url('../../fonts/WindsorBQ-Elongated.otf');
    font-style: normal;
}

@font-face {
    font-family: 'Windsor Cond Light';
    src: url('../../fonts/WindsorEF-LightCondensed.otf');
    font-style: normal;
}

@font-face {
    font-family: 'Fira Code';
    src: url('../../fonts/FiraCode-Regular.ttf');
    font-style: normal;
}

@font-face {
    font-family: 'Gill Sans Italic';
    src: url('../../fonts/GillSansStd-Italic.otf');
    font-style: normal;
}


@font-face {
    font-family: 'Franklin Gothic';
    src: url('../../fonts/franklinGothic.otf');
    font-style: normal;
}

@font-face {
    font-family: 'Olive';
    src: url('../../fonts/Olive_DEMO.otf');
    font-style: normal;
}

@font-face {
    font-family: 'Parabole Trial';
    src: url('../../fonts/ParaboleTrial-Regular.otf');
    font-style: normal;
}


@font-face {
    font-family: 'Daniel';
    src: url('../../fonts/danielbd.ttf');
    font-style: normal;
}






/* american */
.author134492.authorstyle, .author134492 {
  font-family: 'Parabole Trial';
  color: #7500DB;
}

/* zai */
.author88210.authorstyle, .author88210 {
  font-family: 'Kavivanar', cursive;
  /* font-family: 'Hershey', cursive;
  letter-spacing: -1px; */
  /* color: #ff7a3f; */
  color: #ff8d1d;
}




/* tristian */
.author173504.authorstyle {
  font-family: bebas-neue-pro, sans-serif;
  font-weight: 400;
  font-style: normal;
  /* color: #ffc20d; */
  color: #ffcf0d;
}

/* krystal */
.author173503.authorstyle {
  font-family: "Courier";
  /* color: teal; */
  color: #20B2AA;
}

/* teresa */
.author173506.authorstyle {
  font-family: Arial;
  /* color: #5113bd; */
  color: #7500DB;
}

/* angus */
.author173746.authorstyle {
  font-family: "Special Elite", cursive;
  font-style: normal;
  color: #000;
}
.author173746.authorstyle p p,
span.author173746.authorstyle ,
.author173746.authorstyle li,
.author173746.authorstyle span.contrib
{
  background-color: #c9f2f2;
}


/* david */
.author37613.authorstyle {
  font-family: 'Wilson';
  font-style: normal;
  color: #000;
}
.author37613.authorstyle p p,
span.author37613.authorstyle ,
.author37613.authorstyle li,
.author37613.authorstyle span.contrib
{
  background-color: #E6E6FA;
}



/* lauren g */
.author17810.authorstyle {
  font-family: "Special Elite", cursive;
  font-style: normal;
  color: #000;
}
.author17810.authorstyle p p,
span.author17810.authorstyle ,
.author17810.authorstyle li,
.author17810.authorstyle span.contrib
{
  /* background-color: #f2ffad; */
  background-color: yellow;
}




/* tash */
.author159845.authorstyle {
  font-family: "Ubuntu", sans-serif;
  color: #df73ff;
  font-weight: 300;
}


/* diana */
.author173736.authorstyle {
  font-family: "Ubuntu", sans-serif;
  color: #7851A9;
  font-weight: 300;
}


/* jessica */
.author173499.authorstyle {
  font-family: Helvetica;
  color: #289d2c;
}

/* paras */
.author173502.authorstyle {
  font-family: Courier;
  color: #6b8e23;
}


/* ryan */
.author23411.authorstyle {
  font-family: 'Lato', sans-serif;
  color: #20B2AA;
  font-style: normal;
  font-weight: 400;
}

/* kevin */
.author173500.authorstyle {
  font-family: 'Reckless';
  /* color: #DD1327; */
  color: #FF0000;
  line-height: 1.3;
}

/* trevor */
.author173534.authorstyle {
  font-family: Arial;
  color: purple;
}

/* timur */
.author7454.authorstyle {
  font-family: Times;
  color: #ff0000;
} .author7454.authorstyle p {
  font-style: italic;
  letter-spacing: -.03em;
}

/* sara */
.author173494.authorstyle {
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 500;
  color: #0000ee;
}

/* connor */
.author13842.authorstyle {
  font-family: 'IBM Plex Sans', sans-serif;
  color: #B9E7B6;
 -webkit-text-stroke-width: 1px;
 -webkit-text-stroke-color: #8CBB89;
  font-weight: 700;
} .author13842.authorstyle p p,
.author13842.authorstyle li {
  /* background-color: #B9E7B6; */
}


/* lissa */
.author173501.authorstyle {
  font-family: 'Bodoni';
  color: #800020;
}

/* miranda */
.author173613.authorstyle {
  font-family: 'OCR';
  /* color: #FF7A66; */
  color: #ff8d1d;
}

/* cameron */
.author173508.authorstyle {
  font-family: 'Alte Haas';
  color: yellow;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #000;
}

/* lauren m. */
.author173730.authorstyle {
  font-family: 'Arial';
  color: #FF00FF;
}

/* Heather */
.author173830.authorstyle {
  font-family: 'Redaction';
  color: #ff008a;
}


/* rahel */
.author162734.authorstyle {
  font-family: Georgia;
  /* color: #000066; */
  color: #0000ee;
}


/* sus */
.author173737.authorstyle {
  font-family: 'Gill Sans Italic';
  /* color: #0511CF; */
  color: #0000ee;
}

/* tyler */
.author25047.authorstyle {
  font-family: 'Fira Code';
  color: #FF0000;
}

/* makshya */
.author173495.authorstyle {
  font-family: Helvetica;
  color: #F5C4C0;
  /* font-weight: bold; */
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #e09d98;
}


/* .author173495.authorstyle {
  font-style: normal;
  font-family: Helvetica;
  color: #000;
}
.author173495.authorstyle p p,
.author173495.authorstyle li {
  background-color: #F5C4C0;
} */





/* matt ross */
.author126808.authorstyle {
  font-family: 'Roboto Mono', monospace;
  /* color: #556b2f; */
  color: #6b8e23;
} .author126808.authorstyle p {
  font-style: italic;
}

/* ilona */
.author72134.authorstyle {
  font-family: 'Kavivanar', cursive;
  color: #CC7722;
}



/* lluvia */
.author160124.authorstyle {
  font-family: 'Daniel';
  color: #E4007C;
}


/* leanne */
.author173505.authorstyle {
  /* font-family: 'Hershey Futura'; */
  font-family: 'Gotham';
  color: #159ED9;
}



/* ————————————————————————————————————————————————————————————————————————————————————— */


/* makayla */
.author102862.authorstyle {
  font-family: 'Avara';
  /* color: orange; */
  color: #ffcf0d;
}

/* bronte */
.author173511.authorstyle {
  font-family: 'Hershey Times', cursive;
  /* color: #b08213; */
  color: #CC7722;
} .author173511.authorstyle p {
  letter-spacing: -1px;
}
